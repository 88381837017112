// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alljobs-js": () => import("./../../../src/pages/alljobs.js" /* webpackChunkName: "component---src-pages-alljobs-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-companies-js": () => import("./../../../src/pages/companies.js" /* webpackChunkName: "component---src-pages-companies-js" */),
  "component---src-pages-countries-js": () => import("./../../../src/pages/countries.js" /* webpackChunkName: "component---src-pages-countries-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-post-job-js": () => import("./../../../src/pages/post_job.js" /* webpackChunkName: "component---src-pages-post-job-js" */),
  "component---src-pages-relocations-js": () => import("./../../../src/pages/relocations.js" /* webpackChunkName: "component---src-pages-relocations-js" */),
  "component---src-pages-verified-partner-cancelled-payment-js": () => import("./../../../src/pages/verified_partner/cancelled_payment.js" /* webpackChunkName: "component---src-pages-verified-partner-cancelled-payment-js" */),
  "component---src-pages-verified-partner-js": () => import("./../../../src/pages/verified_partner.js" /* webpackChunkName: "component---src-pages-verified-partner-js" */),
  "component---src-pages-verified-partner-successful-payment-js": () => import("./../../../src/pages/verified_partner/successful_payment.js" /* webpackChunkName: "component---src-pages-verified-partner-successful-payment-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-company-js": () => import("./../../../src/templates/company.js" /* webpackChunkName: "component---src-templates-company-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

